<svelte:options tag="cdlc-experience-months-input" />

<script>
  import { createEventDispatcher } from 'svelte';
  import { get_current_component } from 'svelte/internal';

  const svelteDispatch = createEventDispatcher();
  const component = get_current_component();

  export let value = null;

  const dispatch = (name, detail) => {
    svelteDispatch(name, detail);
    component.dispatchEvent &&
      component.dispatchEvent(new CustomEvent(name, { detail }));
  };

  function handleInput(e) {
    dispatch('change', e.target.value);
  }
</script>

<div class="LeadMatching ExperienceMonths">
  <div class="ExperienceMonths-input">
    <input type="number" min="0" max="60" on:input={handleInput} {value} />
    {#if value !== null}
      <div class="LeadMatching-selector">
        <div
          class="LeadMatching-selectorButton"
          style="margin: 0; display: flex"
        >
          <button
            style="border: none; margin: none;"
            on:click={() => {
              value = null;
              dispatch('change', null);
            }}
          >
            clear
          </button>
        </div>
      </div>
    {/if}
  </div>
</div>

<style src="./ExperienceMonths.scss"></style>
