<svelte:options tag="cdlc-mobile-post" />

<script>
  import './MobilePostHeader/MobilePostHeader.svelte';
  import './MobilePostAd/MobilePostAd.svelte';
  import './MobilePostArticle/MobilePostArticle.svelte';
  import './MobilePostLink/MobilePostLink.svelte';
  import './MobilePostMessage/MobilePostMessage.svelte';
  import './MobilePostPhoto/MobilePostPhoto.svelte';
  import './MobilePostReview/MobilePostReview.svelte';
  import './MobilePostVideo/MobilePostVideo.svelte';
  import './MobilePostFooter/MobilePostFooter.svelte';
  import './MobilePostImageSlider/MobilePostImageSlider.svelte';
  import './MobilePostPoll/MobilePostPoll.svelte';
  import './MobilePostDeleted/MobilePostDeleted.svelte';
  import './MobilePostSensitiveOverlay/MobilePostSensitiveOverlay.svelte';
  import './MobilePostThread/MobilePostThread.svelte';
  import debug from 'debug';
  import _ from 'lodash';
  import { createEventDispatcher, tick } from 'svelte';
  import { get_current_component, onMount } from 'svelte/internal';

  const log = debug('cdlc:MobilePost'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      if (name === 'update') {
        post = post;
      }
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  let api = 'https://m.cdllife.com';
  // let api = 'http://localhost:5000';

  export let post = null;
  export let post_id;
  export let mode = 'embed'; // embed, interactive
  export let options = {
    askSensitive: true,
    showEllipses: true,
  };
  export let user_engagements = {
    liked: false,
  };
  //boolean to control if links look like old link style or like Article previews
  let linksArticles = true;
  let loading = true;
  let globalHashTags = [
    'rant',
    'dashcam',
    'motivation',
    'taco-tuesday',
    'copilot',
    'truckselfie',
  ];

  $: loadData(post, post_id);
  $: postTags = _.filter(_.get(post, 'dimensions'), d => /HASH_/g.test(d));
  $: mergedOptions = _.merge(
    {
      askSensitive: true,
      showEllipses: true,
    },
    options
  );

  function needsTopMargin(post) {
    const postType = _.get(post, 'postType');
    if (!postType) return false;
    const isVideo = postType === 'VIDEO';
    const isSinglePhoto =
      postType === 'PHOTO' && _.size(post.photoInformation.imageArray) <= 1;

    return isVideo || isSinglePhoto;
  }

  async function loadData(existingPost, post_id) {
    try {
      await tick();
      log('post_id', post_id);

      if (!post_id) {
        if (existingPost) {
          loading = false;
        }
        return;
      }

      // if there is already a post and either it's the same as the last
      // requested post_id we're already good
      if (existingPost && existingPost._id === post_id) {
        return;
      }

      const postUrl = api + '/api/posts/' + post_id;
      const res = await fetch(postUrl);
      const json = await res.json();
      post = json.data;

      log('loaded', post);
      if (post.postType === 'REVIEW') {
        await loadLinkedData();
      }

      loading = false;
    } catch (error) {
      log('error getting post', error);
      loading = false;
    }
  }

  async function loadLinkedData() {
    const loaders = _.map(_.get(post, 'link_references'), ref => {
      return new Promise(async (resolve, reject) => {
        // all set --
        if (_.get(post, `link_data.${ref.title}`)) return resolve();

        // not all set ---
        const url = `${api}/api/${ref.route}/${ref.id}`,
          res = await fetch(url),
          json = await res.json();
        log('place', json);
        _.set(post, `link_data.${ref.title}`, json && json.data);
        resolve();
      });
    });
    await Promise.all(loaders);
  }

  function click() {
    if (mode === 'embed') {
      const url = `https://m.cdllife.com/post/` + post._id;
      window.open(url, '_blank');
    } else if (mode === 'interactive') {
      dispatch('open');
    }
  }
</script>

<div
  class={`MobilePost MobilePost--${mode} ${
    post &&
    post.states &&
    post.states.isSensitive &&
    _.get(mergedOptions, 'askSensitive')
      ? 'MobilePost--sensitive'
      : ''
  }`}
  on:click={click}
>
  {#if post && !loading && post.source === 'THREAD'}
    <cdlc-mobile-post-thread {post} {dispatch} {user_engagements} />
  {:else if post && !loading && post.source !== 'THREAD'}
    <slot name="above-header">
      {#if post.postType === 'VIDEO' && _.get(post, 'videoInformation.youtubeId')}
        <div class="MobilePost-banner">
          <div class="MobilePost-banner-item">
            <div>Shared via</div>
            <svg
              height="1.25em"
              width="1.25em"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 461.001 461.001"
              xml:space="preserve"
              fill="#000000"
              ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g><g id="SVGRepo_iconCarrier"
                ><g
                  ><path
                    d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
                    style="fill: rgb(246, 28, 13);"
                  ></path></g
                ></g
              ></svg
            >
          </div>
          <div
            class="MobilePost-banner-item MobilePost-banner-item--right"
            on:click={event => {
              event.stopPropagation();
              dispatch('youtube-view-more');
            }}
          >
            <div>View More</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
              />
            </svg>
          </div>
        </div>
      {/if}
    </slot>

    {#if !_.get(post, 'videoInformation.youtubeId')}
      <cdlc-mobile-post-header {post} {dispatch} options={mergedOptions} />
    {/if}

    {#if post && post.states && post.states.isArchived}
      <cdlc-mobile-post-deleted {post} {dispatch} />
    {:else}
      {#if post && post.states && post.states.isSensitive && _.get(mergedOptions, 'askSensitive')}
        <cdlc-mobile-post-sensitive-overlay {post} {dispatch} />
      {/if}

      {#if post.postType === 'AD' && post.adInformation.adSource === 'DIRECT_AD'}
        <cdlc-mobile-post-ad {post} {mode} {dispatch} />
      {:else if post.postType === 'ARTICLE'}
        <cdlc-mobile-post-article {post} {dispatch} />
      {:else if post.postType === 'LINK'}
        {#if linksArticles}
          <cdlc-mobile-post-article {post} {dispatch} />
        {:else if !linksArticles}
          <cdlc-mobile-post-link {post} {dispatch} />
        {/if}
      {:else if post.postType === 'MESSAGE'}
        <cdlc-mobile-post-message {post} {dispatch} />
      {:else if post.postType === 'PHOTO'}
        <cdlc-mobile-post-photo {post} {dispatch} />
      {:else if post.postType === 'REVIEW'}
        <cdlc-mobile-post-review {post} {dispatch} />
      {:else if post.postType === 'VIDEO'}
        <cdlc-mobile-post-video {post} {dispatch} />
      {/if}

      <cdlc-mobile-post-poll {post} {user_engagements} {dispatch} />

      {#if _.size(postTags)}
        <div
          class="MobilePost-hashtags {needsTopMargin(post)
            ? 'MobilePost-hashtags--paddingTop'
            : ''}"
        >
          {#each postTags as hashtag}
            <div
              class="MobilePost-hashtags-hashtag"
              on:click={event => {
                event.stopPropagation();
                dispatch('hash-tag-filter', hashtag);
              }}
            >
              {#if _.includes(globalHashTags, _.replace(hashtag, 'HASH_', ''))}
                <svg
                  height="1em"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M10 4L7 20M17 4L14 20M5 8H20M4 16H19"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                  </g>
                </svg>
              {:else}
                <svg
                  class="stars"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M325.8 152.3c1.3 4.6 5.5 7.7 10.2 7.7s8.9-3.1 10.2-7.7L360 104l48.3-13.8c4.6-1.3 7.7-5.5 7.7-10.2s-3.1-8.9-7.7-10.2L360 56 346.2 7.7C344.9 3.1 340.7 0 336 0s-8.9 3.1-10.2 7.7L312 56 263.7 69.8c-4.6 1.3-7.7 5.5-7.7 10.2s3.1 8.9 7.7 10.2L312 104l13.8 48.3zM115.7 346.2L75.5 307l55.5-8.1c15.6-2.3 29.2-12.1 36.1-26.3l24.8-50.3 24.8 50.3c7 14.2 20.5 24 36.1 26.3l55.5 8.1-40.2 39.2c-11.3 11-16.4 26.9-13.8 42.4l9.5 55.4-49.5-26.1c-14-7.4-30.7-7.4-44.7 0L120 444l9.5-55.4c2.7-15.6-2.5-31.4-13.8-42.4zm54.7-188.8l-46.3 94L20.5 266.5C.9 269.3-7 293.5 7.2 307.4l74.9 73.2L64.5 483.9c-3.4 19.6 17.2 34.6 34.8 25.3l92.6-48.8 92.6 48.8c17.6 9.3 38.2-5.7 34.8-25.3L301.6 380.6l74.9-73.2c14.2-13.9 6.4-38.1-13.3-40.9L259.7 251.4l-46.3-94c-8.8-17.9-34.3-17.9-43.1 0zm258.4 85.8l11 38.6c1 3.6 4.4 6.2 8.2 6.2s7.1-2.5 8.2-6.2l11-38.6 38.6-11c3.6-1 6.2-4.4 6.2-8.2s-2.5-7.1-6.2-8.2l-38.6-11-11-38.6c-1-3.6-4.4-6.2-8.2-6.2s-7.1 2.5-8.2 6.2l-11 38.6-38.6 11c-3.6 1-6.2 4.4-6.2 8.2s2.5 7.1 6.2 8.2l38.6 11z"
                  />
                </svg>
              {/if}
              <div>{_.replace(hashtag, 'HASH_', '')}</div>
            </div>
          {/each}
        </div>
      {/if}

      {#if _.get(post, 'videoInformation.youtubeId')}
        <cdlc-mobile-post-header
          {post}
          {dispatch}
          options={_.merge(mergedOptions, {
            hideAvatar: true,
            showEllipses: false,
          })}
        />
      {/if}

      {#if !(post.postType === 'AD' && post.adInformation.adSource === 'DIRECT_AD')}
        <cdlc-mobile-post-footer {post} {user_engagements} {dispatch} />
      {/if}
    {/if}

    <slot name="below-footer" />
  {/if}
</div>

<style src="./MobilePost.scss">
</style>
