<svelte:options tag="cdlc-mobile-post-article" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { onMount, tick } from 'svelte';
  import '../MobilePostText/MobilePostText.svelte';

  const log = debug('cdlc:MobilePostArticle');

  export let dispatch = () => {};
  export let post;
  let photoUrl;
  let articleDomainLink;

  let description;
  let linkUrl;
  let title;
  let message;

  onMount(async () => {
    await tick();
    if (post) {
      if (post.postType === 'ARTICLE') {
        photoUrl = _.get(post, 'articleInformation.photoUrl');
        description = _.get(post, 'articleInformation.description');
        linkUrl = _.get(post, 'articleInformation.linkUrl');
        title = _.get(post, 'articleInformation.title');

        setArticleDomainLink();
      } else {
        photoUrl = _.get(post, 'linkInformation.image');
        description = _.get(post, 'linkInformation.description');
        linkUrl = _.get(post, 'linkInformation.linkUrl');
        message = _.get(post, 'linkInformation.caption');
        title =
          _.get(post, 'linkInformation.title') ||
          _.get(post, 'linkInformation.linkUrl') ||
          _.get(post, 'commentInformation.text');

        setLinkDomainLink();
      }
    }
    log('post: ', post);
  });
  function setArticleDomainLink() {
    const link = _.lowerCase(_.get(post, 'articleInformation.sourceId'));
    if (_.includes(link, 'cdllife')) {
      articleDomainLink = 'cdllife.com';
    } else if (_.includes(link, 'livetrucking')) {
      articleDomainLink = 'livetrucking.com';
    } else if (_.includes(link, 'driveusatruck')) {
      articleDomainLink = 'driveusatruck.com';
    } else {
      articleDomainLink = _.get(
        post,
        'articleInformation.metadata.domainLabel',
        ''
      );
    }
  }

  function setLinkDomainLink() {
    const link = _.lowerCase(_.get(post, 'linkInformation.linkUrl'));
    if (_.includes(link, 'cdllife')) {
      articleDomainLink = 'cdllife.com';
    } else if (_.includes(link, 'livetrucking')) {
      articleDomainLink = 'livetrucking.com';
    } else if (_.includes(link, 'driveusatruck')) {
      articleDomainLink = 'driveusatruck.com';
    }
  }
</script>

{#if post}
  <div class="MobilePostArticle">
    {#if post.postType === 'ARTICLE' || post.postType === 'LINK'}
      {#if post.postType === 'LINK'}
        <cdlc-mobile-post-text
          {dispatch}
          {post}
          text={message}
          max_length="275"
        />
      {/if}
      <cdlc-mobile-post-text
        {dispatch}
        {post}
        text={description}
        max_length="275"
      />

      <div class="MobilePost-postSection" style="padding: 0px 0px;">
        <div
          class="MobilePost-postSection-linkContent"
          on:click={() => {
            dispatch('body-link', { link: linkUrl });
          }}
        >
          {#if photoUrl}
            <img
              src={photoUrl}
              class="MobilePost-postSection-linkContent-image"
              style="width: 100%; margin-bottom: 10px;"
            />
          {/if}
          <!-- TITLE -->
          <div class="MobilePost-postSection-title">
            {title}
          </div>
          <!-- LINK for articles-->
          {#if post.postType === 'ARTICLE'}
            <div class="MobilePostArticle-url">{articleDomainLink}</div>
          {/if}
        </div>
      </div>
    {/if}
  </div>
{/if}

<style src="./MobilePostArticle.scss">
</style>
